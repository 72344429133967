import { me } from 'api/auth'
import socket from 'api/socket'
import { env } from 'env'
import { history } from 'store'
import {
	getUser,
	putChangeRamalStatus,
	putChangeRamalType,
	putUser,
} from '../../../api/user'

export const USER_LOGIN = 'USER_LOGIN',
	USER_LOGOUT = 'USER_LOGOUT',
	USER_ERROR = 'USER_ERROR',
	USER_STATUS = 'USER_STATUS',
	USER_AUTH = 'USER_AUTH',
	USER_ME_UPDATE = 'USER_ME_UPDATE',
	USER_SETUP_2FA = 'USER_SETUP_2FA',
	USER_VERIFY_2FA = 'USER_VERIFY_2FA'

export function userMe(throwError = true) {
	return dispatch => {
		me()
			.then(response => {
				const { role, theme } = response.data
				dispatch({
					type: USER_LOGIN,
					...response.data,
					theme: theme.length > 0 ? theme : 'light',
					role: Number(role),
				})
			})
			.catch(error => {
				if (throwError) {
					const status = error?.response?.status
					const path = window.location.pathname
						.split('/')
						.filter(x => x.length > 0)
					if (status === 401 && (path.length === 0 || path[0] !== 'login')) {
						history.push('/login')
					}
					dispatch({
						type: USER_ERROR,
						...error?.response?.data,
					})
				} else {
					dispatch({
						type: USER_LOGOUT,
					})
				}
			})
	}
}

export function userLogout(me, onSuccess) {
	return dispatch => {
		const func = async () => {
			if (localStorage.getItem('token')) {
				try {
					await fetch(`${env.REACT_APP_API_URL}/api/v1/authentication/logout`, {
						method: 'GET',
						'Content-Type': 'application/json',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					})

					dispatch({
						type: USER_LOGOUT,
					})
					//terminate()
					localStorage.removeItem('token')
					localStorage.removeItem('protocols')
					socket.disconnect()
					onSuccess && onSuccess()
				} catch (e) {
					console.log(e)
				}
			}
		}
		func()
	}
}

export function getUserAvailable(id) {
	return dispatch => {
		getUser(id)
			.then(res => {
				let distribuition = res.data[0].distribuition.role.departments.map(
					x => ({
						...x,
						channel: x.channel.map(y => ({ ...y, max_protocol: 0 })),
					})
				)
				dispatch(
					putUserAvailable(id, {
						...res.data[0],
						distribuition: {
							...res.data[0].distribuition,
							role: {
								...res.data[0].distribuition.role,
								departments: distribuition,
							},
						},
					})
				)
			})
			.catch(err => console.log(err))
	}
}

export function putUserAvailable(id, data) {
	return dispatch => {
		let formData = new FormData()
		Object.keys(data).map(k => {
			formData.append(
				k,
				k === 'department'
					? JSON.stringify(data[k].map(d => d.id))
					: k === 'distribuition'
						? JSON.stringify(data[k])
						: data[k]
			)
		})

		putUser(formData)
			.then(res => {
				dispatch({
					type: USER_ME_UPDATE,
					distribuition: data.distribuition,
				})
			})
			.catch(err => console.log(err))
	}
}

export function putUserRamalStatus(data, snackbar) {
	return (dispatch, getState) => {
		putChangeRamalStatus(data)
			.then(res => {
				dispatch({
					type: USER_ME_UPDATE,
					ramal_status: data.ramal_status,
				})
				if (res.data.error) {
					snackbar(`${res.data.error}`, {
						variant: 'error',
						resumeHideDuration: 5000,
					})
				} else {
					if (data.ramal_status === 'available') {
						snackbar(
							`${getState().auth.name} você está conectado ao ramal ${getState().auth.ramal} e disponível para receber chamadas.`,
							{ variant: 'success' }
						)
					} else {
						snackbar(`Status do discador atualizado com sucesso!`, {
							variant: 'success',
						})
					}
				}
			})
			.catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.error === 'Ramal não logado no softphone') {
						snackbar(
							`${getState().auth.name} você precisa se conectar ao SoftPhone para ficar disponível e receber as chamadas.`,
							{
								variant: 'error',
								resumeHideDuration: 5000,
							}
						)
					} else {
						snackbar(`${err.response.data.error}`, {
							variant: 'error',
							resumeHideDuration: 5000,
						})
					}
				}
			})
	}
}

export function putUserRamalType(data, snackbar) {
	return (dispatch, getState) => {
		putChangeRamalType(data)
			.then(res => {
				dispatch({
					type: USER_ME_UPDATE,
					...data,
				})
				if (res.data.error) {
					snackbar(`${res.data.error}`, {
						variant: 'error',
						resumeHideDuration: 5000,
					})
				} else {
					snackbar(`Tipo de discador atualizado com sucesso!`, {
						variant: 'success',
					})
				}
			})
			.catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.error === 'Ramal não logado no softphone') {
						snackbar(
							`${getState().auth.name} você precisa se conectar ao SoftPhone para ficar disponível e receber as chamadas.`,
							{
								variant: 'error',
								resumeHideDuration: 5000,
							}
						)
					} else {
						snackbar(`${err.response.data.error}`, {
							variant: 'error',
							resumeHideDuration: 5000,
						})
					}
				}
			})
	}
}

export function putUserMe(me) {
	return dispatch => {
		let formData = new FormData()
		Object.keys(me).map(k => {
			formData.append(
				k,
				k === 'department' ? JSON.stringify(me[k].map(d => d.id)) : me[k]
			)
		})
		putUser(formData)
			.then(res => {
				dispatch({
					type: USER_ME_UPDATE,
					language: me.language,
					theme: me.theme,
					...res.data,
				})
			})
			.catch(err => console.log(err))
	}
}
