import { LocalizationProvider } from '@material-ui/pickers'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import moment from 'moment'
import 'moment/locale/pt-br'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import I18n from 'redux-i18n'
import Loader from './components/parts/loader/loader'
import { translations } from './locale/translations'
import StoreProvider from './store'

window.env = {}

//import App from './app'
const App = Loadable({ loader: () => import('./app'), loading: Loading })
function Loading({ error }) {
	if (error) {
		return error
	} else {
		return <Loader />
	}
}

moment.locale('pt-br')
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.render(
	<StoreProvider>
		<I18n translations={translations} initialLang="pt-Br">
			<LocalizationProvider
				dateAdapter={MomentUtils}
				locale={`pt-br`}
				dateLibInstance={moment}
			>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</LocalizationProvider>
		</I18n>
	</StoreProvider>,
	document.getElementById('root')
)
